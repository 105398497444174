import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CustomizerReducer from './customizer/CustomizerSlice';
import userReducer from './apps/user/userSlice';
import { combineReducers } from 'redux';
import informationReducer from './apps/information/informationSlice';
import configurationReducer from './apps/configuration/configurationSlice';
import visitorsTraficReducer from './apps/traficVisitors/traficVisitorsSlice';
import modulesReducer from './apps/modules/moduleSlice';
import malwareReducer from './apps/malware/malwareSlice';
import banReducer from './apps/banAutomatique/banAutomatiqueSlice';
import logEntryReducer from './apps/logEntry/logEntrySlice';
import emailCheckReducer from './apps/emailCheck/emailCheckSlice';
import networkScanReducer from './apps/networkScan/networkScanSlice';
import dashboardReducer from './apps/dashboard/dashboradSlice';
import translateReducer from './apps/translateText/translateTextSlice';
import jiraReducer from './apps/jiraTicket/jiraTicketSlice';
import onboardingReducer from './apps/onboarding/onboardingSlice';

import patternReducer from './apps/pattern/patternSlice'
import packReducer from './apps/paimentMethod/packSlice'
import packModuleReducer from './apps/paimentMethod/packModuleSlice'
import moduleListReducer from './apps/moduleList/moduleListSlice';
import paymentReducer from './apps/paimentMethod/paymentSlice';
import virementReducer from './apps/paimentMethod/VirementSlice';
import freeTrialReducer from './apps/paimentMethod/freeTrialSlice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ // Liste uniquement les reducers que tu veux persister
    "customizer",
    "userReducer", 
    "informationReducer", 
    "configurationReducer",
    "onboarding",
    "dashboardReducer",
    "visitorsTraficReducer",
    "modulesReducer",
    "malwareReducer",
    "banReducer",
    "logEntryReducer",
    "networkScanReducer",
    "translateReducer",
    "jiraReducer",
    "patternReducer",
    "packReducer",
    "packModuleReducer",
    "moduleListReducer",
    "paymentReducer",
    "virementReducer",
    "freeTrialReducer"


  ]
};

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  emailCheckReducer:emailCheckReducer,
  userReducer: userReducer,
  informationReducer:informationReducer,
  configurationReducer:configurationReducer,
  visitorsTraficReducer:visitorsTraficReducer,
  modulesReducer:modulesReducer,
  malwareReducer:malwareReducer,
  banReducer:banReducer,
  logEntryReducer:logEntryReducer,
  networkScanReducer:networkScanReducer,
  dashboardReducer:dashboardReducer,
  translateReducer:translateReducer,
  jiraReducer:jiraReducer,
  onboarding: onboardingReducer,
  patternReducer:patternReducer,
  packReducer: packReducer,
  packModuleReducer: packModuleReducer,
  moduleListReducer: moduleListReducer,
  paymentReducer: paymentReducer,
  virementReducer:virementReducer,
  freeTrialReducer:freeTrialReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);


