import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";

// Create FreeTrial

// Fetch all freeTrials
export const getAllFreeTtrialsAdmin = createAsyncThunk(
    "freetrials/getAllFreeTtrialsAdmin",
    async ({ userRole }, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/freeTrial/getAllFreeTtrialsAdmin?userRole=${userRole}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch FreeTrial');
        }
    }
);

export const getFreeTrialClient = createAsyncThunk(
    "freeTrial/getFreeTrialClient",
    async ({ userId, userRole }, { rejectWithValue }) => {
        try {
            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(`/freeTrial/getFreeTrialClient?userRole=${userRole}&userId=${userId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            console.log(response, "ressd")

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch freeTrial');
        }
    }
);

const initialState = {
    freeTrialList: [],
    freeTrialListUser: [],
    freeTrialData: null,
    freeTrialRef: null, // To store the freeTrialRef
    freeTrialStatus: 'idle',
    error: null,
    transactionSaved: false,
    isFetchingGetFreeTrialAdmin: false,
    isSuccessGetFreeTrialAdmin: false,
    isErrorGetFreeTrialAdmin: false,
    isFetchingGetFreeTrialClient: false,
    isSuccessGetFreeTrialClient: false,
    isErrorGetFreeTrialClient: false,
}
const freeTrialSlice = createSlice({
    name: 'freeTrial',
    initialState,
    reducers: {
        clearFreeTrialdData: () => initialState

    },
    extraReducers: (builder) => {
        builder
            // Get All FreeTrials
            .addCase(getAllFreeTtrialsAdmin.pending, (state) => {
                state.isFetchingGetFreeTrialAdmin = true;
                state.isSuccessGetFreeTrialAdmin = false;
                state.isErrorGetFreeTrialAdmin = false
            })
            .addCase(getAllFreeTtrialsAdmin.fulfilled, (state, { payload }) => {
                state.isFetchingGetFreeTrialAdmin = false;
                state.isSuccessGetFreeTrialAdmin = true;
                state.isErrorGetFreeTrialAdmin = false;
                state.freeTrialList = payload;  // The list of all freeTrials
            })
            .addCase(getAllFreeTtrialsAdmin.rejected, (state, action) => {
                state.isFetchingGetFreeTrialAdmin = false;
                state.isSuccessGetFreeTrialAdmin = false;
                state.isErrorGetFreeTrialAdmin = true;
                state.freeTrialList = [];  // The list of all freeTrials
            })

            .addCase(getFreeTrialClient.pending, (state) => {
                state.isFetchingGetFreeTrialClient = true;
                state.isSuccessGetFreeTrialClient = false;
                state.isErrorGetFreeTrialClient = false;
            })
            .addCase(getFreeTrialClient.fulfilled, (state, { payload }) => {
                console.log('payloaaad', payload)
                // state.status = "succeeded";
                state.isFetchingGetFreeTrialClient = false;
                state.isSuccessGetFreeTrialClient = true;
                state.isErrorGetFreeTrialClient = false;
                state.freeTrialList = payload; // Ensure state is fully replaced
                console.log("Updated Redux State (freeTrial):", state.freeTrialList);
            })
            .addCase(getFreeTrialClient.rejected, (state, action) => {
                state.isFetchingGetFreeTrialClient = false;
                state.isSuccessGetFreeTrialClient = false;
                state.isErrorGetFreeTrialClient = true;
                state.freeTrialList = [];
            })
    }
});
export const { clearFreeTrialdData } = freeTrialSlice.actions;

export default freeTrialSlice.reducer;

// export const { actions: freeTrialActions, reducer: freeTrialReducer } = freeTrialSlice;
