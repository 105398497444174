import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";

// Define the async thunk for initializing payment
export const initializePayment = createAsyncThunk(
    'payment/initializePayment',
    async (paymentDetails, { rejectWithValue }) => {
        try {

            let url = `/payments/init-payment`;
            const response = await Axios.post(url, paymentDetails);
            const result = response.data.data;
            console.log(result, "result")
            window.location.href = result.payUrl;
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
// Fetch payment by ID
export const getPaymentById = createAsyncThunk(
    'payment/getPaymentById',
    async ({ paymentRef, userId }, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/payments/get-payment-byid?userId=${userId}&paymentRef=${paymentRef}`, {
                params: { payment_ref: paymentRef, userId: userId }  // Pass payment_ref as a query parameter

            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Fetch all payments
export const getAllPaymentsAdmin = createAsyncThunk(
    "Payments/fetchAllAdmin",
    async ({ userRole, userId }, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/payments/getAllPaymentsAdmin?userRole=${userRole}&userId=${userId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Payment');
        }
    }
);


// In handlePaymentWebhook
export const handlePaymentWebhook = createAsyncThunk(
    'payment/handlePaymentWebhook',
    async (webhookData, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await Axios.post('/payments/webhook', webhookData); // Destructure response.data
            const { status, paymentRef, user, pack, site } = data; // Destructure properties from response data
            console.log(status, "statusstatusstatus")
            // Check payment status
            // if (status === 'completed') {
            //     // If payment is successful, dispatch email
            //     await dispatch(sendValidationInscriptionEmail({
            //         email: user.email,
            //         username: user.username,
            //         packName: pack.name,
            //         phoneNumber: user.phoneNumber,
            //         Country: user.Country,
            //         rqtNumber: pack.rqt_number === -1 ? "Unlimited" : pack.rqt_number.toString(),
            //         priceV: pack.price.value,
            //         priceC: pack.price.currency,
            //         nbrSite: pack.nombre_site,
            //         siteName: site.url,
            //     }));
            // }
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


// Update Payments
export const updatePaymentById = createAsyncThunk(
    "payment/update",
    async ({ id, updateData, userRole }, { rejectWithValue }) => {
        try {
            const url = `/payments/updatePaymentById?id=${id}&userRole=${userRole}`;
            const response = await Axios.put(url, updateData);
            return response.data;
        } catch (error) {
            console.error("Error updating Payments:", error);
            return rejectWithValue(error.response?.data || { msg: "Something went wrong during the update." });
        }
    }
);
// get payment for every client by user id
export const getPaymentsByUserId = createAsyncThunk(
    "payments/fetchByUserId",
    async ({ userId, userRole }, { rejectWithValue }) => {
        try {
            let url = `/payments/getPaymentsByUserId?`
            if (userRole === 'Admin') {
                url += `userRole=${userRole}`
            }
            else {
                url += `userId=${userId}`
            }
            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(url);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Payment');
        }
    }
);

export const getPaymentsBySiteId = createAsyncThunk(
    "payments/getPaymentsBySiteId",
    async ({ siteId }, { rejectWithValue }) => {
        try {
            let url = `/payments/getPaymentsBySiteId?siteId=${siteId}`

            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(url);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Payment');
        }
    }
);


// -------------------- emails ------------------------------
export const sendValidationInscriptionEmail = createAsyncThunk(
    'email/sendValidationInscriptionEmail',
    async ({ email, username, packName, phoneNumber, Country, rqtNumber, priceV, priceC, nbrSite, siteName }, { rejectWithValue }) => {
        try {
            const url = `/payments/sendValidationInscriptionEmail`;
            console.log('Payload being sent:', { email, username, packName }); // Log payload
            const response = await Axios.post(url, { email, username, phoneNumber, Country, packName, priceV, priceC, rqtNumber, nbrSite, siteName });
            console.log('Response from backend:', response.data); // Log backend response
            return response.data;
        } catch (error) {
            console.error('Error in sendValidationInscriptionEmail:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || 'Error sending test email');
        }
    }
);

export const createFacture = createAsyncThunk(
    'payment/createFacture',
    async (paymentData, { rejectWithValue }) => {
        try {
            const response = await Axios.post('/factures/payment-success', paymentData); // Send payment data to backend
            return response.data; // Response will be { message: 'Facture created successfully' }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// -----------------------------------------------------------
// Async thunk for sending payment details via email
export const sendFactureEmail = createAsyncThunk(
    'payment/sendFactureEmail',
    async ({ email, username, phoneNumber, packName, paymentRef, amount, token, userId, siteName, packModule, dateExp }, { rejectWithValue }) => {
        try {
            const url = `/payments/sendFactureEmail?userId=${userId}`; // Include userId in the URL query
            const response = await Axios.post(url, {
                email,
                username,
                phoneNumber,
                packName,
                paymentRef,
                amount,
                token,
                siteName,
                packModule,
                dateExp
            });
            console.log(response, "email data")
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const saveTransaction = createAsyncThunk(
    'payment/saveTransaction',
    async ({ paymentRef, transactionData, siteId }, { rejectWithValue }) => {
        // Check if there is any transaction data to save
        if (!transactionData || transactionData.length === 0) {
            console.log("No transaction data to save.");
            return rejectWithValue('No transaction data to save');
        }

        try {
            const response = await Axios.put(`/payments/updatePaymentByRef?payment_ref=${paymentRef}`, {
                transactionData, siteId
            });

            return response.data;
        } catch (error) {
            console.error("Error saving transaction:", error);
            return rejectWithValue(error.response?.data || 'Error saving transaction.');
        }
    }
);

export const updateRejectedPaymentByRef = createAsyncThunk(
    'payment/updateRejectedPaymentByRef',
    async ({ paymentRef, transactionData, isSaved, isFailed, isActive, siteId }, { rejectWithValue }) => {
        try {
            const response = await Axios.put(`/payments/updateRejectedPaymentByRef?payment_ref=${paymentRef}`, {
                transactionData,
                isSaved,
                isFailed,
                isActive,
                siteId
            });

            return response.data;
        } catch (error) {
            console.error("Error saving transaction:", error);
            return rejectWithValue(error.response?.data || 'Error saving transaction.');
        }
    }
);




const initialState = {
    paymentList: [],
    paymentListUser: [],
    PaymentBySiteId: [],
    paymentData: null,
    paymentData2: null,
    paymentRef: null,
    paymentStatus: 'idle',
    error: null,
    transactionSaved: false,
    isFetchingInitializePayment: false,
    isSuccessInitializePayment: false,
    isErrorInitializePayment: false,
    isFetchinggetPaymentById: false,
    isSuccessgetPaymentById: false,
    isErrorgetPaymentById: false,
    isFetchinggetGetAllPaymentsAdmin: false,
    isSuccessgetGetAllPaymentsAdmin: false,
    isErrorgetGetAllPaymentsAdmin: false,
    isFetchinggetGetPaymentsByUserId: false,
    isSuccessgetGetPaymentsByUserId: false,
    isErrorgetGetPaymentsByUserId: false,
    isFetchingHandlePaymentWebhook: false,
    isSuccessHandlePaymentWebhook: false,
    isErrorHandlePaymentWebhook: false,
    isFetchingUpdatePaymentById: false,
    isSuccessUpdatePaymentById: false,
    isErrorUpdatePaymentById: false,
    isFetchingSendValidationInscriptionEmail: false,
    isSuccessSendValidationInscriptionEmail: false,
    isErrorSendValidationInscriptionEmail: false,
    isFetchingSendFactureEmail: false,
    isSuccessSendFactureEmail: false,
    isErrorSendFactureEmail: false,
    isFetchingSaveTransaction: false,
    isSuccessSaveTransaction: false,
    isErrorSaveTransaction: false,
    isFetchingUpdateRejectedPaymentByRef: false,
    isSuccessUpdateRejectedPaymentByRef: false,
    isErrorUpdateRejectedPaymentByRef: false,
    isFetchingGetPaymentsBySiteId: false,
    isSuccessGetPaymentsBySiteId: false,
    isErrorGetPaymentsBySiteId: false,
}
const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setTransactionSaved: (state, action) => {
            state.transactionSaved = action.payload;
        },
        clearPaymentdData: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // Initialize Payment
            .addCase(initializePayment.pending, (state) => {
                state.isFetchingInitializePayment = true;
                state.isSuccessInitializePayment = false;
                state.isErrorInitializePayment = false;
            })
            .addCase(initializePayment.fulfilled, (state, action) => {
                state.isFetchingInitializePayment = false;
                state.isSuccessInitializePayment = true;
                state.isErrorInitializePayment = false;
                state.paymentData = action.payload;
                state.paymentRef = action.payload.data.paymentRef;


            })
            .addCase(initializePayment.rejected, (state, action) => {
                state.isFetchingInitializePayment = false;
                state.isSuccessInitializePayment = false;
                state.isErrorInitializePayment = true;
            })
            // .addCase(createFacture.fulfilled, (state, action) => {
            //     console.log('Facture created:', action.payload);
            // })
            // .addCase(createFacture.rejected, (state, action) => {
            //     console.error('Error creating facture:', action.payload);
            // })
            // Get Payment By ID
            .addCase(getPaymentById.pending, (state) => {
                state.isFetchinggetPaymentById = true;
                state.isSuccessgetPaymentById = false;
                state.isErrorgetPaymentById = false;
            })
            .addCase(getPaymentById.fulfilled, (state, action) => {
                state.isFetchinggetPaymentById = false;
                state.isSuccessgetPaymentById = true;
                state.isErrorgetPaymentById = false;
                state.paymentData = action.payload.data.payment;
                state.paymentData2 = action.payload.payment;
            })
            .addCase(getPaymentById.rejected, (state, action) => {
                state.isFetchinggetPaymentById = false;
                state.isSuccessgetPaymentById = false;
                state.isErrorgetPaymentById = true;
            })

            // Get All Payments
            .addCase(getAllPaymentsAdmin.pending, (state) => {
                state.isFetchinggetGetAllPaymentsAdmin = true;
                state.isSuccessgetGetAllPaymentsAdmin = false;
                state.isErrorgetGetAllPaymentsAdmin = false;
            })
            .addCase(getAllPaymentsAdmin.fulfilled, (state, { payload }) => {
                state.isFetchinggetGetAllPaymentsAdmin = false;
                state.isSuccessgetGetAllPaymentsAdmin = true;
                state.isErrorgetGetAllPaymentsAdmin = false;
                state.paymentList = payload;  // The list of all payments
            })

            .addCase(getAllPaymentsAdmin.rejected, (state, action) => {
                state.isFetchinggetGetAllPaymentsAdmin = false;
                state.isSuccessgetGetAllPaymentsAdmin = false;
                state.isErrorgetGetAllPaymentsAdmin = true;
                state.paymentList = [];  // The list of all payments
            })
            // Get All getPaymentsByUserId
            .addCase(getPaymentsByUserId.pending, (state) => {
                state.isFetchinggetGetPaymentsByUserId = true;
                state.isSuccessgetGetPaymentsByUserId = false;
                state.isErrorgetGetPaymentsByUserId = false;
            })
            .addCase(getPaymentsByUserId.fulfilled, (state, { payload }) => {
                state.isFetchinggetGetPaymentsByUserId = false;
                state.isSuccessgetGetPaymentsByUserId = true;
                state.isErrorgetGetPaymentsByUserId = false;
                state.paymentListUser = payload?.data; // Ensure state is fully replaced
                // console.log("Updated Redux State (Payment):", state?.payments);
            })
            .addCase(getPaymentsByUserId.rejected, (state, action) => {
                state.isFetchinggetGetPaymentsByUserId = false;
                state.isSuccessgetGetPaymentsByUserId = false;
                state.isErrorgetGetPaymentsByUserId = true;
                state.paymentListUser = [];
            })

            // Handle Payment Webhook
            .addCase(handlePaymentWebhook.pending, (state) => {
                state.isFetchingHandlePaymentWebhook = true;
                state.isSuccessHandlePaymentWebhook = false;
                state.isErrorHandlePaymentWebhook = false;
            })
            .addCase(handlePaymentWebhook.fulfilled, (state, action) => {
                state.isFetchingHandlePaymentWebhook = false;
                state.isSuccessHandlePaymentWebhook = true;
                state.isErrorHandlePaymentWebhook = false;
                state.webhookResponse = action.payload;
            })
            .addCase(handlePaymentWebhook.rejected, (state, action) => {
                state.isFetchingHandlePaymentWebhook = false;
                state.isSuccessHandlePaymentWebhook = false;
                state.isErrorHandlePaymentWebhook = true;
            })

            // Update Payment
            .addCase(updatePaymentById.pending, (state) => {
                state.isFetchingUpdatePaymentById = true;
                state.isSuccessUpdatePaymentById = false;
                state.isErrorUpdatePaymentById = false;
            })
            .addCase(updatePaymentById.fulfilled, (state, action) => {
                state.isFetchingUpdatePaymentById = false;
                state.isSuccessUpdatePaymentById = true;
                state.isErrorUpdatePaymentById = false;
                const updatedPayment = action.payload?.updatedPayment;

                if (!updatedPayment) return; // Si aucune mise à jour reçue, ne rien faire

                const index = state.paymentList?.findIndex(payment => payment?._id === updatedPayment._id);

                if (index !== -1 && index !== undefined) {
                    state.paymentList[index] = updatedPayment; // Mise à jour sécurisée
                }
            })

            .addCase(updatePaymentById.rejected, (state, action) => {
                state.isFetchingUpdatePaymentById = false;
                state.isSuccessUpdatePaymentById = false;
                state.isErrorUpdatePaymentById = true;
            })
            // ----------------- emails ------------------------
            //sendValidationInscriptionEmail 
            .addCase(sendValidationInscriptionEmail.pending, (state) => {
                state.isFetchingSendValidationInscriptionEmail = true;
                state.istate.sSuccessSendValidationInscriptionEmail = false;
                state.isErrorSendValidationInscriptionEmail = false;
            })
            .addCase(sendValidationInscriptionEmail.fulfilled, (state) => {
                state.isFetchingSendValidationInscriptionEmail = false;
                state.istate.sSuccessSendValidationInscriptionEmail = true;
                state.isErrorSendValidationInscriptionEmail = false;
            })
            .addCase(sendValidationInscriptionEmail.rejected, (state, action) => {
                state.isFetchingSendValidationInscriptionEmail = false;
                state.istate.sSuccessSendValidationInscriptionEmail = false;
                state.isErrorSendValidationInscriptionEmail = true;
            })
            // ------------------------ facture par email -------------------------
            .addCase(sendFactureEmail.pending, (state) => {
                state.isFetchingSendFactureEmail = true;
                state.istate.sSuccessSendFactureEmail = false;
                state.isErrorSendFactureEmail = false;
            })
            .addCase(sendFactureEmail.fulfilled, (state, action) => {
                state.isFetchingSendFactureEmail = false;
                state.istate.sSuccessSendFactureEmail = true;
                state.isErrorSendFactureEmail = false;
                console.log("Email sent successfully!");
            })
            .addCase(sendFactureEmail.rejected, (state, action) => {
                state.isFetchingSendFactureEmail = false;
                state.istate.sSuccessSendFactureEmail = false;
                state.isErrorSendFactureEmail = true;
                console.error("Error sending email:", action.payload);
            })
            // Handle successful transaction saving
            .addCase(saveTransaction.pending, (state) => {
                state.isFetchingSaveTransaction = true;
                state.isSuccessSaveTransaction = false;
                state.isErrorSaveTransaction = false;
            })
            .addCase(saveTransaction.fulfilled, (state, { payload }) => {
                // Update the state to reflect that the transaction has been saved
                state.isFetchingSaveTransaction = false;
                state.isSuccessSaveTransaction = true;
                state.isErrorSaveTransaction = false;
                state.isSaved = true;
                state.isActive = true;
                state.paymentData = payload.transactionData;
            })
            .addCase(saveTransaction.rejected, (state, action) => {
                // Handle case where the save operation was rejected
                state.isFetchingSaveTransaction = false;
                state.isSuccessSaveTransaction = false;
                state.isErrorSaveTransaction = true;
            })
            // ---------------------------- rejected -------------
            .addCase(updateRejectedPaymentByRef.pending, (state) => {
                state.isFetchingUpdateRejectedPaymentByRef = true;
                    state.isSuccessUpdateRejectedPaymentByRef = false;
                    state.isErrorUpdateRejectedPaymentByRef = false;
            })
            // Handle successful transaction saving
            .addCase(updateRejectedPaymentByRef.fulfilled, (state, { payload }) => {
                // Update the state to reflect that the transaction has been saved
                state.isFetchingUpdateRejectedPaymentByRef = false;
                    state.isSuccessUpdateRejectedPaymentByRef = true;
                    state.isErrorUpdateRejectedPaymentByRef = false;
                    state.isSaved = true;
                state.isActive = true;
                state.paymentData = payload.transactionData;
            })
            .addCase(updateRejectedPaymentByRef.rejected, (state, action) => {
                // Handle case where the save operation was rejected
                state.isFetchingUpdateRejectedPaymentByRef= false;
                state.isSuccessUpdateRejectedPaymentByRef= false;
                state.isErrorUpdateRejectedPaymentByRef= true;
            })
            // ------- Payment by siteId -----------
            // Get Payment By ID
            .addCase(getPaymentsBySiteId.pending, (state) => {
                state.isFetchingGetPaymentsBySiteId= true;
                state.isSuccessGetPaymentsBySiteId= false;
                state.isErrorGetPaymentsBySiteId= false;
            })
            .addCase(getPaymentsBySiteId.fulfilled, (state, action) => {
                state.isFetchingGetPaymentsBySiteId= false;
                state.isSuccessGetPaymentsBySiteId= true;
                state.isErrorGetPaymentsBySiteId= false;
                state.PaymentBySiteId = action.payload;
            })
            .addCase(getPaymentsBySiteId.rejected, (state, action) => {
                state.isFetchingGetPaymentsBySiteId= false;
                state.isSuccessGetPaymentsBySiteId= false;
                state.isErrorGetPaymentsBySiteId= true;

            })
    }
});
export const { clearPaymentdData, paymentActions } = paymentSlice.actions;

// export const { actions: paymentActions,clearPaymentdData, reducer: paymentReducer } = paymentSlice;
export default paymentSlice.reducer;