import { lazy } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Navigate, Route } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import Onboarding from "../layouts/onboarding/Onboarding";

import Security from '../views/auth/Security';
import TwoFA from '../views/auth/TwoFA';
import ForgotPassword from '../views/auth/ForgotPassword';
import UserManagement from '../views/apps/userManagement/UserManagement';
import MainLayout from '../layouts/MainLayout';


/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const TableauDeBord = Loadable(lazy(() => import('../views/Acceuil/TableauDeBord')));

/***** Apps ****/
const InformationsSurLeSite = Loadable(lazy(() => import('../views/apps/informationSurSite/InformationsSurLeSite')));
const Settings = Loadable(lazy(() => import('../views/apps/settings/Settings')));
const ConfigurationsPHP = Loadable(lazy(() => import('../views/apps/configurations/Configurations')));
const SqlInjection = Loadable(lazy(() => import('../views/apps/sqlInjection/SqlInjection')));
const SqlInjectionIA = Loadable(lazy(() => import('../views/apps/sqlinjectionIA/SqlInjectionIa')));
const EmailCheck  = Loadable(lazy(() => import('../views/apps/emailCheck/EmailCheck')));
const BanAutomatique = Loadable(lazy(() => import('../views/apps/banAutomatique/BanList')));
const RobotsDetection = Loadable(lazy(() => import('../views/apps/robotDetection/RobotsDetection')));
const SusVisitors = Loadable(lazy(() => import('../views/apps/susVisitors/SusVisitors')));
const LogEntry = Loadable(lazy(() => import('../views/apps/logEntry/LogEntry')));
const LogEntryIa = Loadable(lazy(() => import('../views/apps/logEntryIA/LogEntryIa')));
const JiraTicket = Loadable(lazy(() => import('../views/apps/jiraTicket/JiraTicket')));
const ListTickets=Loadable(lazy(() => import('../views/apps/jiraTicket/ListTickets')));
const VerifyEmail=Loadable(lazy(() => import('../views/auth/VerifyEmail')));
const SiteList = Loadable(lazy(() => import('../views/apps/sites/SiteList')));

const ExposedServices = Loadable(lazy(() => import('../views/apps/exposedServices/ExposedServices')));
const Trafic = Loadable(lazy(() => import('../views/apps/trafic/Trafic')));
const UserValidation = Loadable(lazy(() => import('../views/apps/userValidation/UserValidation')));
const SiteValidation = Loadable(lazy(() => import('../views/apps/siteValidation/SiteValidation')));
const ParametresAdmin = Loadable(lazy(() => import('../views/apps/parametreAdmin/ParametresAdminList')));
const PatternAdmin = Loadable(lazy(() => import('../views/apps/parametreAdmin/PatternAdmin')));
const Profile = Loadable(lazy(() => import('../views/auth/Profile')));
const OnboardingStats =Loadable(lazy(() => import ("../views/apps/onboarding/onboardingStats")));
const ChangePassword=Loadable(lazy(() => import('../views/auth/ChangePassword')));
const ResetPassword=Loadable(lazy(() => import('../views/auth/ResetPassword')));

const UsersOnboardingStats =Loadable(lazy(() => import ("../views/apps/onboarding/usersOnboardingStats")));
const OnboardingFeedback = Loadable(lazy(() => import("../views/apps/onboarding/FeedbackStats")));
const Reporting = Loadable(lazy(() => import("../views/apps/Repporting/Reporting")));

const PackageCards = Loadable(lazy(() => import("../views/apps/package/PackageCards")));
const InscriptionClient = Loadable(lazy(() => import("../views/apps/inscriptionDetail/InscriptionClient")));

const PaymentSuccess= Loadable(lazy(() => import("../views/apps/package/PaymentSuccess")));
const PaymentFailure= Loadable(lazy(() => import("../views/apps/package/PaymentFailure")));
const RenderComponent= Loadable(lazy(() => import('./RenderComponent')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));


const createThemeRoutesWithLang = (lang, PackageData) => {

  const activeHrefs = new Set(PackageData?.modulesActive?.map(module => module.href));
  const inActiveHrefs = new Set(PackageData?.modulesInActive?.map(module => module.href));
  return [
  {
    path: "/",
    element: <MainLayout />,
      children: [
    {
      path: ``, // Préfixe chaque route avec le code de langue
      element: <FullLayout />,
      children: [
        { index: true, element: <Navigate to={`/${lang}/dashboard`} replace /> },
        { path: `${lang}/dashboard`, name: 'Dashboard', element: <TableauDeBord /> },
        {
          path: `${lang}/site-information`, name: 'Information',
          element: (
            <RenderComponent
              path="site-information"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={InformationsSurLeSite}
            />
          )
        },
        {
          path: `${lang}/php-configuration`, name: 'Configuration',
          element: (
            <RenderComponent
              path="php-configuration"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={ConfigurationsPHP}
            />
          )
        },
        {
          path: `${lang}/cyber-threat-modules/email-check`, name: 'Email Check',
          element: (
            <RenderComponent
              path="cyber-threat-modules/email-check"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={EmailCheck}
            />
          )
          //  element: <EmailCheck /> 
        },
        { path: `${lang}/options/settings`, name: 'Settings', element: <Settings /> },

        {
          path: `${lang}/security-modules/sql-xss-injection`, name: 'SQL and XSS Injection',
          element: (
            <RenderComponent
              path="security-modules/sql-xss-injection"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={SqlInjection}
            />
          )
          //  element: <SqlInjection /> 

        },
        {
          path: `${lang}/security-modules/detection-by-ai`, name: 'SQL Injection AI',
          element: (
            <RenderComponent
              path="security-modules/detection-by-ai"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={SqlInjectionIA}
            />
          )
          // element: <SqlInjectionIA /> 
        },
        {
          path: `${lang}/security-modules/ip-management`, name: 'Automatic Ban',
          element: (
            <RenderComponent
              path="security-modules/ip-management"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={BanAutomatique}
            />
          )
          //  element: <BanAutomatique />
        },
        {
          path: `${lang}/cyber-threat-modules/service-check`, name: 'Exposed Services',
          element: (
            <RenderComponent
              path="cyber-threat-modules/service-check"
              PackageData={PackageData}
              inActiveHrefs={inActiveHrefs}
              Component={ExposedServices}
            />
          )
          //  element: <ExposedServices /> 
        },
        {
          path: `${lang}/security-modules/bot-detection`, name: 'Bot Detection',
          element: (
            <RenderComponent
              path="security-modules/bot-detection"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={RobotsDetection}
            />
          )
          //  element: <RobotsDetection />
        },
        {
          path: `${lang}/security-modules/threat-log`, name: 'Threat Log',
          element: (
            <RenderComponent
              path="security-modules/threat-log"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={LogEntry}
            />
          )
          //  element: <LogEntry /> 
        },
        {
          path: `${lang}/security-modules/threat-log-ai`, name: 'Threat Log AI',
          element: (
            <RenderComponent
              path="security-modules/threat-log-ai"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={LogEntryIa}
            />
          )
          //  element: <LogEntryIa /> 

        },
        {
          path: `${lang}/security-modules/suspicious-visitors`, name: 'Suspicious Visitors',
          element: (
            <RenderComponent
              path="security-modules/suspicious-visitors"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={SusVisitors}
            />
          )
          // element: <SusVisitors /> 
        },
        {
          path: `${lang}/apps/traffic`, name: 'Traffic',
          element: (
            <RenderComponent
              path="apps/traffic"
              inActiveHrefs={inActiveHrefs}
              PackageData={PackageData}
              Component={Trafic}
            />
          )
          // element: <Trafic /> 
        },
        { path: `${lang}/apps/user-verification`, name: 'User Verification', element: <UserValidation /> },
        { path: `${lang}/user-management`, name: 'User Management', element: <UserManagement /> },
        { path: `${lang}/apps/site-verification`, name: 'Site Verification', element: <SiteValidation /> },
        { path: `${lang}/settings`, name: 'Admin Settings', element: <ParametresAdmin /> },
      { path: `${lang}/patterns`, name: 'Admin Pattern', element: <PatternAdmin /> },
 
        { path: `${lang}/auth/profile`, name: 'profile',  element: <Profile /> },
        { path: `${lang}/auth/security`, name: 'security',  element: <Security /> },
        { path: `${lang}/technical-support/open-ticket`, name: 'Support technique',  element: <JiraTicket /> },
        { path: `${lang}/technical-support/tickets-list`, name: 'Tickets list',  element: <ListTickets /> },
        { path: `${lang}/options/sites-list`, name: 'Sites list',  element: <SiteList /> },
        {
          path: `${lang}/options/reporting`, name: 'Reporting', element: (
            <RenderComponent
              path="options/reporting"
              PackageData={PackageData}
              inActiveHrefs={inActiveHrefs}
              Component={Reporting}
            />
          ),
        }  ,
            { path: `${lang}/onboardingstats` , name: 'Onboarding stats', element: <OnboardingStats /> },
      { path: `${lang}/onboardingstats/users` , name: 'Users Onboarding stats', element: <UsersOnboardingStats /> },
      { path: `${lang}/onboardingstats/feedback`, name: 'Onboarding stats', element: <OnboardingFeedback /> },
      { path: `${lang}/pricing/packageCards`, name: 'Paiment', element: <PackageCards /> },

      { path: `${lang}/pricing/inscriptionsDetails`, name: 'Inscriptions Client', element: <InscriptionClient /> },
      { path: `${lang}/payment-success`, name: 'Payment Success', element: <PaymentSuccess /> },
      { path: `${lang}/payment-failure`, name: 'Payment Fail', element: <PaymentFailure /> },
  
  
        { path: `*`, element: <Navigate to={`/${lang}/dashboard`} replace /> },
      ],
    },
    {
      path: `${lang}/auth`, // Utilisez la variable lang pour construire le chemin
      element: <BlankLayout />,
      children: [
        { path: '404', element: <Error /> },
        { path: `*`, element: <Navigate to={`${lang}/auth/loginformik`} replace /> },
        { path: `registerformik`, element: (
        // <GoogleReCaptchaProvider reCaptchaKey="6Lf4UQslAAAAAEwalsGDY-JAMhi6-S7HyC9TzZIU">
        <GoogleReCaptchaProvider reCaptchaKey="6LfQERopAAAAAEn8Cr_Su7Ym7ZuGwiQ9RMNxqv2O">
        <RegisterFormik />
        </GoogleReCaptchaProvider>)
      },
        { path: `loginformik`, element:(
        // <GoogleReCaptchaProvider reCaptchaKey="6Lf4UQslAAAAAEwalsGDY-JAMhi6-S7HyC9TzZIU">
        <GoogleReCaptchaProvider reCaptchaKey="6LfQERopAAAAAEn8Cr_Su7Ym7ZuGwiQ9RMNxqv2O">
        <LoginFormik /> 
        </GoogleReCaptchaProvider>)},
        { path: `2fa`, element:(
          // <GoogleReCaptchaProvider reCaptchaKey="6Lf4UQslAAAAAEwalsGDY-JAMhi6-S7HyC9TzZIU">
          <GoogleReCaptchaProvider reCaptchaKey="6LfQERopAAAAAEn8Cr_Su7Ym7ZuGwiQ9RMNxqv2O">
        <TwoFA /> 
        </GoogleReCaptchaProvider>)},
      { path: 'verifyEmail', element: <VerifyEmail /> },
      { path: 'ResetPassword', element: <ResetPassword /> },
      { path: 'reset-password/:token', element: <ChangePassword /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      ],
    },
  ]
}
  ]}




export default createThemeRoutesWithLang;